import React, { useState, useEffect, useRef } from 'react';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { classNames } from 'primereact/utils';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Divider } from 'primereact/divider';
import { Button } from 'primereact/button';
import { RadioButton } from 'primereact/radiobutton';
import { Dropdown } from 'primereact/dropdown';
import { Dialog } from 'primereact/dialog';
import { TriStateCheckbox } from 'primereact/tristatecheckbox';
import { Messages } from 'primereact/messages';
import isNull from 'lodash/fp/isNull';
import '../../../App.scss';
import './DeviceUpdate.scss';

import {
  getAllDevicesWithFW,
  getLatestStableVersions,
  getLatestBetaVersions,
  updateDeviceAPI,
  getFWUpdateStatusAPI,
} from '../../../api/service/DeviceService';
import { UpdateDevicePayload } from '../../../api/service/service.types';

import { PRODUCT, ENV_LIST } from '../../../api/routes/route';

export const DeviceUpdate = () => {
  const [filters, setFilters] = useState<any | null>({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    serialNumber: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    firmwareVersion: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    nipperVersion: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    isConnected: { value: null, matchMode: FilterMatchMode.EQUALS },
    updateStatus: { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
  });
  const [devices, setDevices] = useState(null);
  const [selectedDevices, setSelectedDevices] = useState(null);
  const [selectedFw, setSelectedFw] = useState<any>(null);
  const [selectedNipper, setSelectedNipper] = useState<any>(null);
  const [selectedEnv, setSelectedEnv] = useState<any>(ENV_LIST.STABLE);
  const [selectedType, setSelectedType] = useState<any>(PRODUCT.LIFEGUARD);
  const [loading, setLoading] = useState(true);
  const [fwVersions, setFwVersions] = useState([]);
  const [nipperVersions, setNipperVersions] = useState([]);
  const [refreshGrid, setRefreshGrid] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [message, setMessage] = useState(null);
  const mountedRef = useRef(true);
  const msg = useRef(null);

  const updateStatuses = [
    'UPDATE_REQUESTED',
    'UPDATE_INPROGRESS',
    'UPDATE_COMPLETED',
    'UPDATE_FAILED',
    'NOT_AVAILABLE',
  ];
  const envs = [ENV_LIST.STABLE, ENV_LIST.BETA];

  const filtersMap = {
    filters: { value: filters, callback: setFilters },
  };
  useEffect(() => {
    setLoading(true);
    getAllDevicesWithFW().then((payload) => {
      console.log('DeviceServices: ', { payload });
      if (payload?.data?.response) {
        const res = payload?.data?.response;
        getFWUpdateStatusAPI().then((statusPayload) => {
          if (statusPayload?.data?.response) {
            (statusPayload?.data?.response || []).forEach((row) => {
              console.log('Looping row ', row);
              (payload?.data?.response || []).forEach((device, index) => {
                if (row.DeviceId === device.id) {
                  console.log('matching record found', row, device, index);
                  res[index].fwStatus = { status: row.status, category: row.category, updatedAt: row.updatedAt };
                  console.log('res[index]', res[index]);
                }
              });
            });
            console.log(res);
            setLoading(false);
            return setDevices(res);
          } else if (statusPayload?.error?.message) {
            msg.current.show({
              severity: 'error',
              sticky: true,
              content: <React.Fragment>{statusPayload?.error?.message}</React.Fragment>,
            });
            return 'Error retrieving Devices';
          }
        });
      } else if (payload?.error?.message) {
        msg.current.show({
          severity: 'error',
          sticky: true,
          content: <React.Fragment>{payload?.error?.message}</React.Fragment>,
        });
        return 'Error retrieving Devices';
      }
      return 'Error retrieving Devices';
    });
    return () => {
      console.log('unmount called at Device update');
      mountedRef.current = false;
    };
  }, [refreshGrid]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setSelectedFw(null);
    setSelectedNipper(null);
    if (selectedEnv === ENV_LIST.STABLE) {
      // Latest Stable lifeguard firmware versions
      getLatestStableVersions(PRODUCT.LIFEGUARD).then((payload) => {
        console.log('getLatestStableVersions lifeguard: ', payload);
        if (payload?.data?.response) {
          return setFwVersions(payload?.data?.response);
        }
        return 'Error retrieving Devices';
      });

      // Latest Stable nipper versions
      getLatestStableVersions(PRODUCT.NIPPER).then((payload) => {
        console.log('getLatestStableVersions nipper: ', payload);
        if (payload?.data?.response) {
          return setNipperVersions(payload?.data?.response);
        }
        return 'Error retrieving Devices';
      });
    } else {
      // Latest beta lifeguard firmware versions
      getLatestBetaVersions(PRODUCT.LIFEGUARD).then((payload) => {
        console.log('getLatestBetaVersions: ', payload);
        if (payload?.data?.response) {
          return setFwVersions(payload?.data?.response);
        }
        return 'Error retrieving Devices';
      });

      // Latest beta nipper versions
      getLatestBetaVersions(PRODUCT.NIPPER).then((payload) => {
        console.log('getLatestBetaVersions nipper: ', payload);
        if (payload?.data?.response) {
          return setNipperVersions(payload?.data?.response);
        }
        return 'Error retrieving Devices';
      });
    }
    return () => {
      mountedRef.current = false;
    };
  }, [selectedEnv]); // eslint-disable-line react-hooks/exhaustive-deps

  const onFWChange = (e: { value: any }) => {
    setSelectedFw(e.value);
  };
  const onNipperChange = (e: { value: any }) => {
    setSelectedNipper(e.value);
  };
  const onEnvChange = (e: { value: any }) => {
    console.log('onEnvChange ', e);
    setSelectedEnv(e.value);
  };
  const onSubmit = () => {
    console.log('values', selectedFw, selectedEnv, selectedNipper, selectedType, selectedDevices);
    if (selectedType === PRODUCT.LIFEGUARD && isNull(selectedFw)) {
      setErrorMessage(true);
      setMessage('FIRMWARE version not selected. Please select and hit UPDATE');
    } else if (selectedType === PRODUCT.NIPPER && isNull(selectedNipper)) {
      setErrorMessage(true);
      setMessage('NIPPER version not selected. Please select and hit UPDATE');
    } else if (isNull(selectedDevices)) {
      setErrorMessage(true);
      setMessage('Please select at least one device to update');
    } else {
      setShowMessage(true);
      if (selectedType === PRODUCT.NIPPER) {
        setMessage(`Are you sure you want to update selected devices to ${selectedEnv} Nipper v${selectedNipper}?`);
      } else {
        setMessage(`Are you sure you want to update selected devices to ${selectedEnv} Firmware v${selectedFw}?`);
      }
    }
  };

  const onUpdate = () => {
    setLoading(true);
    console.log('onUpdate: ', selectedFw, selectedEnv, selectedNipper, selectedType, selectedDevices);
    setShowMessage(false);
    const selectedSN = selectedDevices.map((item) => item.serialNumber);
    const payload: UpdateDevicePayload = {
      serialNumbers: selectedSN,
      firmwareVersion: selectedType === PRODUCT.LIFEGUARD ? selectedFw : selectedNipper,
      product: selectedType,
      releaseVersion: selectedEnv,
    };
    console.log('updateDeviceAPI: ', payload);
    updateDeviceAPI(payload).then((response) => {
      setLoading(false);
      setRefreshGrid(!refreshGrid);
      console.log('updateDeviceAPI resp: ', response);
    });
  };

  const onRefresh = () => {
    setRefreshGrid(!refreshGrid);
  };

  const onSelectDevices = (e) => {
    setSelectedDevices(e.value);
    console.log('selectedDevices: ', e.value);
  };

  const connStatusBodyTemplate = (rowData) => {
    return (
      <i
        className={classNames('pi', {
          'text-green-500 pi-check-circle': rowData.isConnected,
          'text-pink-500 pi-times-circle': !rowData.isConnected,
        })}
      ></i>
    );
  };
  const updateStatusBodyTemplate = (rowData) => {
    let updateStatus = 'NOT_AVAILABLE';
    if (rowData?.fwStatus?.status) {
      console.log('updateStatusBodyTemplate', rowData);
      updateStatus = rowData?.fwStatus?.status;
    }
    return <span className={`customer-badge status-${updateStatus}`}>{updateStatus}</span>;
  };
  const statusItemTemplate = (option) => {
    console.log('statusItemTemplate', option);
    return <span className={`customer-badge status-${option}`}>{option}</span>;
  };
  const filterApply = (e, options) => {
    console.log('filterApply', e.value, options.index, options);
    return options.filterCallback(e.value, options.index);
  };

  const updateStatusFilterTemplate = (options) => {
    return (
      <Dropdown
        value={options.value}
        options={updateStatuses}
        onChange={(e) => {
          console.log('e : ', e, options);
          return filterApply(e, options);
        }}
        itemTemplate={statusItemTemplate}
        placeholder="Select a Status"
        className="p-column-filter"
        showClear
      />
    );
  };

  const connStatusFilterTemplate = (options) => {
    return <TriStateCheckbox value={options.value} onChange={(e) => options.filterCallback(e.value)} />;
  };

  const onGlobalFilterChange = (event, filtersKey) => {
    console.log('onGlobalFilterChange ', filtersKey, event.target);
    const value = event.target.value;
    let filters1 = { ...filtersMap[filtersKey].value };
    filters1['global'].value = value;
    filtersMap[filtersKey].callback(filters1);
  };

  const renderHeader = (filtersKey) => {
    return (
      <div className="grid">
        <div className="col-6 flex align-items-left justify-content-left">
          <div className="p-fluid">
            <span className="p-input-icon-left">
              <i className="pi pi-search" />
              <InputText
                type="search"
                value={filters['global'].value || ''}
                onChange={(e) => onGlobalFilterChange(e, filtersKey)}
                placeholder="Global Search"
              />
            </span>
          </div>
        </div>
        <div className="col-6 flex align-items-center justify-content-end">
          <Button icon="pi pi-refresh" onClick={onRefresh} />
        </div>
      </div>
    );
  };

  const header = renderHeader('filters');

  const dialogFooter = (
    <div className="flex justify-content-center">
      <Button label="CANCEL" className="p-button-danger" onClick={() => setShowMessage(false)} />
      <Button label="UPDATE" className="p-button-warning" onClick={onUpdate} />
    </div>
  );

  const regionBodyTemplate = (rowData) => {
    switch (rowData.region) {
      case 0:
        return 'Australia';
      case 1:
        return 'Europe';
      case null:
        return 'Unknown';
      default:
        return rowData.region; // ou outro valor padrão que você deseja exibir
    }
  };

  const errorDialogFooter = (
    <div className="flex justify-content-center">
      <Button label="OK" className="p-button-warning" autoFocus onClick={() => setErrorMessage(false)} />
    </div>
  );
  return (
    <div>
      <div className="card">
        <h5>
          <b>Device update</b>
        </h5>
        <div
          className="flex"
          style={{
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <div>
            <b>
              Current {selectedEnv} Firmware version: {fwVersions ? fwVersions[0] : ''}
            </b>
          </div>
          <Divider layout="vertical" />
          <div>
            <b>
              Current {selectedEnv} Nipper version:{nipperVersions ? nipperVersions[0] : ''}
            </b>
          </div>
        </div>
        <Messages ref={msg} />
        <DataTable
          value={devices}
          paginator
          rows={10}
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          rowsPerPageOptions={[10, 25, 50]}
          rowHover
          header={header}
          filters={filters}
          onFilter={(e) => setFilters(e.filters)}
          selection={selectedDevices}
          onSelectionChange={onSelectDevices}
          dataKey="id"
          filterDisplay="menu"
          loading={loading}
          responsiveLayout="scroll"
          emptyMessage="No devices found."
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
        >
          <Column selectionMode="multiple" headerStyle={{ width: '3em' }}></Column>
          <Column
            field="serialNumber"
            header="Serial Number"
            sortable
            filter
            filterPlaceholder="Search by device"
          ></Column>
          <Column
            field="firmwareVersion"
            header="Lifeguard"
            sortable
            filter
            filterPlaceholder="Search by version"
          ></Column>
          <Column field="nipperVersion" header="Nipper" sortable filter filterPlaceholder="Search by version"></Column>
          <Column field="modes" header="Modes" sortable filter filterPlaceholder="Search by mode"></Column>
          <Column
            field="region"
            header="Region"
            body={regionBodyTemplate}
            sortable
            filter
            filterPlaceholder="Search by region"
          ></Column>

          <Column
            field="isConnected"
            header="Connected to cloud?"
            dataType="boolean"
            bodyClassName="text-center"
            body={connStatusBodyTemplate}
            sortable
            filter
            filterElement={connStatusFilterTemplate}
          ></Column>
          <Column
            field="updateStatus"
            header="Update status"
            body={updateStatusBodyTemplate}
            filterMenuStyle={{ width: '14rem' }}
            style={{ minWidth: '12rem' }}
            sortable
            filter
            filterElement={updateStatusFilterTemplate}
          ></Column>
        </DataTable>
        <br />

        {/* Update Dialogue box */}
        <Dialog
          visible={showMessage}
          onHide={() => setShowMessage(false)}
          position="top"
          footer={dialogFooter}
          showHeader={false}
          breakpoints={{ '960px': '80vw' }}
          style={{ width: '30vw' }}
        >
          <div className="flex justify-content-center flex-column pt-6 px-3">
            <i className="pi pi-question-circle" style={{ fontSize: '5rem', color: 'var(--green-500)' }}></i>
            <h5>Confirm Update!</h5>
            <p style={{ lineHeight: 1.5, textIndent: '1rem' }}>{message}</p>
          </div>
        </Dialog>

        {/* Error Dialogue box */}
        <Dialog
          visible={errorMessage}
          onHide={() => setErrorMessage(false)}
          position="top"
          footer={errorDialogFooter}
          showHeader={false}
          breakpoints={{ '960px': '80vw' }}
          style={{ width: '30vw' }}
        >
          <div className="flex justify-content-center flex-column pt-6 px-3">
            <i className="pi pi-info-circle" style={{ fontSize: '5rem', color: 'red' }}></i>
            <h5>Error!</h5>
            <p style={{ lineHeight: 1.5, textIndent: '1rem' }}>{message}</p>
          </div>
        </Dialog>
        <div
          className="flex"
          style={{
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <div>
            <RadioButton
              inputId="fwType1"
              name="fwType"
              value={PRODUCT.LIFEGUARD}
              onChange={(e) => setSelectedType(e.value)}
              checked={selectedType === PRODUCT.LIFEGUARD}
            />
          </div>
          <div>
            <Dropdown
              value={selectedFw}
              options={fwVersions}
              onChange={onFWChange}
              placeholder="Select a firmware version"
            />
          </div>
          <Divider layout="vertical" />
          <div>
            <RadioButton
              inputId="fwType2"
              name="fwType"
              value={PRODUCT.NIPPER}
              onChange={(e) => setSelectedType(e.value)}
              checked={selectedType === PRODUCT.NIPPER}
            />
          </div>
          <div>
            <Dropdown
              value={selectedNipper}
              options={nipperVersions}
              onChange={onNipperChange}
              placeholder="Select a nipper version"
            />
          </div>
          <Divider layout="vertical" />
          <div>
            <Dropdown
              value={selectedEnv}
              options={envs}
              onChange={onEnvChange}
              placeholder="Select a release version"
            />
          </div>
        </div>
        <br />
        <br />
        <div
          className="flex"
          style={{
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Button label="UPDATE" onClick={onSubmit} loading={loading} />
        </div>
      </div>
    </div>
  );
};
